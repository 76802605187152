"use client"
import Link from "next/link";
import { Label } from "@/components/ui/label"
import { Switch } from "@/components/ui/switch"
import { FcGoogle } from "react-icons/fc";
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "@/components/ui/button"
import { signIn } from "next-auth/react";
import { useRouter, useSearchParams } from "next/navigation";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { useEffect, useState } from "react";
import { Icons } from "../ui/icons";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
var window: any;
const LoginForm = (props: any) => {
    const router = useRouter();
    const searchParams = useSearchParams()
    const [loading, setLoading] = useState(false)
    const [googleLoader, setGoogleLoader] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<any>({
        message: '',
        status: '',
        isError: false
    })
    const newError = searchParams.get('error')
    const formSchema = z.object({
        email:  z.string({ required_error: "Email is required" }).min(1, { message: "Email is required" })
        .regex(/^[A-Za-z0-9@.]+$/, { message: "Invalid email" })
        .email("Invalid email"),

        password: z.string({
            required_error: "Password is required.",
        })
            .describe("Your secure password")
            .min(5, {
                message: "Password must be at least 8 characters.",
            }),

        rememberMe: z.boolean({ required_error: "Remember Me" }).default(false).optional(),
    })

    const form = useForm({
        resolver: zodResolver(formSchema),
    });

    const onSubmit = async (data: any) => {
        setLoading(true);
        try {
            // const result = await signIn("credentials", {
            //     redirect: false,
            //     email: data.email,
            //     password: data.password,
            // })
            const signInResult = await signIn('credentials', {
                redirect: true,
                callbackUrl: '/',
                email: data.email,
                password: data.password,
            })
                .then((response: any) => {
                    setLoading(false)
                    if (response?.error === null && response.status === 200) {
                        setError({
                            message: '',
                            status: '',
                            isError: false
                        })
                        if (typeof window !== 'undefined' && window.navigation.canGoBack) {
                            router.back();
                        } else {
                            // setTimeout(() => {
                            //     router.push('/');
                            // }, 1000);
                        }
                    }
                    else if (response?.status === 401) {
                        setError({
                            message: 'Unable to login, Please check your credentials.',
                            status: response?.ok,
                            isError: true
                        })
                        setTimeout(() => {
                            setError({
                                message: '',
                                status: '',
                                isError: false
                            })
                        }, 3000);
                    }
                })
                .catch((error) => {
                    setError({
                        message: 'Unable to login, Please check your credentials.',
                        status: 401,
                        isError: true
                    })
                    setTimeout(() => {
                        setError({
                            message: '',
                            status: '',
                            isError: false
                        })
                    }, 3000);
                });
        } catch (error) {
        }
    };
    const googleLogin = async () => {
        setGoogleLoader(true);
        const signInResult = signIn('google', {
            redirect: true,
            callbackUrl: '/',
        }).then((response: any) => {
            setGoogleLoader(false)
        })
    }

    useEffect(() => {
        setTimeout(()=>{
            if (newError) {
                    router.push("/login", undefined); // Replace with a static path
            }
        },4000)
    }, [newError, router]);

    
    return (

        <div className="relative">
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
                    <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Email</FormLabel>
                                <FormControl>
                                    <Input placeholder="Email or phone number" className="rounded-none text-sm" {...field}  onKeyDown={(e: any) => {
                                        if (e.keyCode === 32) {
                                            e.preventDefault();
                                        }
                                    }}onDragOver={(e) => {
                                        e.preventDefault();
                                      }}
                                      onDrop={(e) => {
                                        e.preventDefault();
                                      }}/>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="password"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Password</FormLabel>
                                <FormControl className="relative">
                                <div className="relative">
                                    <Input placeholder="Enter password" className="rounded-none text-sm" {...field}
                                        type={showPassword ? 'text' : 'password'}
                                        onKeyDown={(e: any) => {
                                            if (e.keyCode === 32) {
                                                e.preventDefault();
                                            }
                                        }}
                                        maxLength={17}onDragOver={(e) => {
                                            e.preventDefault();
                                          }}
                                          onDrop={(e) => {
                                            e.preventDefault();
                                          }}/>
                                    <Button
                                            type="button"
                                            variant="ghost"
                                            size="sm"
                                            className="absolute right-0 top-6 px-3 py-2 hover:bg-transparent"
                                            onClick={() => setShowPassword((prev) => !prev)}

                                        >
                                            {showPassword ? (
                                                <EyeIcon
                                                    className="h-4 w-4 absolute bottom-8 right-3"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <EyeOffIcon
                                                    className="h-4 w-4 absolute bottom-8 right-3"
                                                    aria-hidden="true"
                                                />
                                            )}
                                            <span className="sr-only absolute">
                                                {showPassword ? "Hide password" : "Show password"}
                                            </span>
                                        </Button></div>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    {(error?.isError || newError) && <div>
                                    <Alert variant={'destructive'}>
                                    <AlertDescription>
                                        {'These credentials do not match our records.'}
                                        {/* {error?.message || newError} */}
                                    </AlertDescription>
                                </Alert></div>}
                    <div className="flex items-center justify-between pt-3">
                        <FormField
                            control={form.control}
                            name="rememberMe"
                            render={({ field }) => (
                                <FormItem className="flex items-center">
                                    <FormControl>
                                        <Switch
                                            id="airplane-mode"
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <div className="ms-2 leading-none p-0" style={{ marginTop: "0" }}>
                                        <Label htmlFor="airplane-mode" className="text-[#626161] text-[12px]">Remember me</Label>
                                    </div>
                                </FormItem>
                            )}
                        />
                        <Link href="/forgot-password" className="text-[#434343] text-[12px] hover:text-[#B73DFF]" >Forgot password?</Link>
                    </div>
                    <Button variant="primary" className="h-[48px] font-semibold text-[14px] mt-8 bg-[#B73DFF]" disabled={loading}>{loading && <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />}Sign In</Button>
                    <Link href="">
                        <Button variant="primary" className="h-[48px] font-semibold text-[12px] mt-5" onClick={googleLogin} disabled={loading}>
                            <FcGoogle className="mr-2 text-[1.5rem]" /> {googleLoader && <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />}sign in with Google</Button>
                    </Link>
                </form>
            </Form>
        </div>
    );
};

export default LoginForm;
